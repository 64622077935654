<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <pre
    ref="output"
    data-automation="log-overlay__output"
    class="log-overlay__output"
    tabindex="0"
    @scroll="onScroll"
  >
    <!-- eslint-disable vue/no-v-for-template-key -->
    <template
      v-for="(entry, i) in entries"
      :key="i"
    >
      <!-- eslint-enable vue/no-v-for-template-key -->
      <time
        class="timestamp"
        :title="entry.timestamp"
      >
        {{ `${localTimeStamp(entry.timestamp)}: ` }}
      </time>
      <LogLine
        :class="['content', entry.source, wrapLongLines ? 'wrap' : '']"
        :data="entry.data"
        :search-term="searchText"
        @count="countMatches"
      />
    </template>
</pre>
</template>

<script>
import { getJobLog, tailJobPath } from '@/api/jobs';
import { utcToLocalTimezone } from '@/utils/timezone';
import LogLine from './LogLine.vue';

export default {
  name: 'LogOutput',
  components: {
    LogLine,
  },
  props: {
    app: {
      type: Object,
      required: true,
    },
    job: {
      type: Object,
      required: true,
    },
    searchText: {
      type: String,
      default: null,
    },
    searchCurrent: {
      type: Number,
      default: 0,
    },
    wrapLongLines: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['matches', 'reset'],
  data() {
    return {
      tail: null,
      entries: [],
      following: true,
      matchCount: 0,
    };
  },
  watch: {
    job: {
      immediate: true,
      handler(job) {
        const { guid } = this.app;
        const { key } = job;

        if (this.tail) {
          this.tail.removeEventListener('entry', this.appendLogEntry);
          this.tail.close();
          this.tail = null;
        }

        getJobLog(guid, key).then(entries => {
          this.entries = entries;
          this.$nextTick().then(() => this.scrollToLatest());
        })
          .then(() => {
            this.tail = new EventSource(tailJobPath(guid, key));
            this.tail.addEventListener('entry', this.appendLogEntry);
          });
      }
    },
    searchText() {
      this.$nextTick().then(() => this.$emit('reset'));
      this.matchCount = 0;
      this.$emit('matches', 0);
    },
    matchCount(count) {
      this.$emit('matches', count);
    },
    searchCurrent(newVal) {
      this.updateCurrent(newVal);
    },
  },
  unmounted() {
    if (this.tail) {
      this.tail.removeEventListener('entry', this.appendLogEntry);
      this.tail.close();
      this.tail = null;
    }
  },
  methods: {
    countMatches(count) {
      this.matchCount += count;
    },
    updateCurrent(value) {
      this.removeCurrent();

      let current;

      if (value === 0) {
        current = this.$refs.output.getElementsByClassName('highlighted')[0];
      } else {
        current = this.$refs.output.getElementsByClassName('highlighted')[value - 1];
      }

      if (current) {
        current.classList.add('current');
        this.following = false;
        this.$nextTick().then(() => this.scrollToCurrent(current));
      }
    },
    removeCurrent() {
      const all = this.$refs.output.getElementsByClassName('current');

      for (const el of all) {
        el.classList.remove('current');
      }
    },
    localTimeStamp(timestamp) {
      return utcToLocalTimezone(timestamp, 'YYYY/MM/DD h:mm:ss A');
    },
    appendLogEntry(e) {
      const entry = JSON.parse(e.data);
      this.entries.push(entry);
      this.$nextTick().then(() => this.scrollToLatest());
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.following = true;
      } else {
        this.following = false;
      }
    },
    scrollToCurrent(el) {
      this.following = false;
      el.scrollIntoView();
    },
    scrollToLatest() {
      if (this.$refs.output && this.following) {
        this.$refs.output.scrollTop = this.$refs.output.scrollHeight;
      }
    },
  }
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.log-overlay__output {
  @include control-visible-focus;
  padding: 0 1rem;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: auto;
  height: 0;
  margin: 8px;
  background-color: $color-white;
  white-space: normal;
  display: grid;
  grid-template-columns: 13rem 1fr;
  color: black;

  >.timestamp {
    color: #737373;
    font-size: 13px;
    font-style: normal;
    padding-right: 1rem;
  }

  >.content {
    white-space: pre;
    color: green;

    &.stdout {
      color: $color-dark-grey-3;
    }

    &.stderr {
      color: $color-error;
      font-style: italic;
    }

    &.wrap {
      white-space: pre-wrap;
    }
  }
}
</style>
