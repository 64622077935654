<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="container">
    <div class="detail__row">
      <div class="detail__section-title">
        Description
      </div>
      <div class="detail__divider" />
    </div>
    <div
      v-if="!environment.description"
      class="detail__row description"
    >
      <!--
        To have this entry match padding/margin/etc w/ the multi-line
        text below, we need to mimic the same div levels 
      -->
      <!-- eslint-disable vue/singleline-html-element-content-newline -->
      <div class="description__empty">
        No description provided for this environment.
      </div>
      <!-- eslint-enable vue/singleline-html-element-content-newline -->
    </div>
    <div
      v-if="environment.description"
      class="detail__row description"
    >
      <!--
        maintaining whitespace in class, need to be very careful
        with this html rendering. normal html formatting causes
        extra whitespace to be inserted, so need to keep description
        string packed into html node. (Disabling linting rule to
        allow this to occur without error.) 
      -->
      <!-- eslint-disable vue/singleline-html-element-content-newline -->
      <div class="description__multiline_text">{{ environment.description }}</div>
      <!-- eslint-enable vue/singleline-html-element-content-newline -->
    </div>
    <div class="detail__row installations-section">
      <div class="detail__section-title">
        Installations
      </div>
      <div class="detail__divider" />
    </div>
    <div
      v-if="!anyInstallations"
      class="detail__row installation-list-container install-warning"
      title="This environment will not be used by the system
        until an administrator has defined installations."
      data-automation="incomplete-warning"
    >
      No installations defined for this environment.
    </div>
    <div
      v-if="anyPythonInstallations"
      class="detail__row installation-list-container"
    >
      <img
        src="/images/python-logo-generic.svg"
        alt="Docker container environment"
        width="100"
        class="python-logo"
      >
      <ul
        class="installation-list"
      >
        <li
          v-for="install in getPythonInstallations"
          :key="install.guid"
          class="installation-entry"
        >
          <div class="installation-entry__container">
            <div class="installation-entry__version">
              {{ 'v' + install.version.major + '.' + install.version.minor + '.' + install.version.patch }}
            </div>
            <div class="installation-entry__path">
              {{ install.path }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="anyQuartoInstallations"
      class="detail__row installation-list-container"
    >
      <img
        src="/images/logoQuarto.png"
        alt="Docker container environment"
        width="100"
        class="quarto-logo"
      >
      <ul
        class="installation-list"
      >
        <li
          v-for="install in getQuartoInstallations"
          :key="install.guid"
          class="installation-entry"
        >
          <div class="installation-entry__container">
            <div class="installation-entry__version">
              {{ 'v' + install.version.major + '.' + install.version.minor + '.' + install.version.patch }}
            </div>
            <div class="installation-entry__path">
              {{ install.path }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="anyRInstallations"
      class="detail__row installation-list-container"
    >
      <img
        src="/images/logoR.png"
        alt="Docker container environment"
        width="100"
        class="r-logo"
      >
      <ul
        class="installation-list"
      >
        <li
          v-for="install in getRInstallations"
          :key="install.guid"
          class="installation-entry"
        >
          <div class="installation-entry__container">
            <div class="installation-entry__version">
              {{ 'v' + install.version.major + '.' + install.version.minor + '.' + install.version.patch }}
            </div>
            <div class="installation-entry__path">
              {{ install.path }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="anyTensorFlowInstallations"
      class="detail__row installation-list-container"
    >
      <img
        src="/images/TF_FullColor_Horizontal.svg"
        alt="Docker container environment"
        width="100"
        class="tensorflow-logo"
      >
      <ul
        class="installation-list"
      >
        <li
          v-for="install in getTensorFlowInstallations"
          :key="install.guid"
          class="installation-entry"
        >
          <div class="installation-entry__container">
            <div class="installation-entry__version">
              {{ 'v' + install.version.major + '.' + install.version.minor + '.' + install.version.patch }}
            </div>
            <div class="installation-entry__path">
              {{ install.path }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="detail__row mt-1rem">
      <div class="detail__section-title">
        Advanced Settings
      </div>
      <div class="detail__divider" />
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        Matching strategy:
      </div>
      <div class="title-value-row__value">
        {{ environment.matching }}
      </div>
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        Supervisor script:
      </div>
      <div class="title-value-row__value">
        {{ supervisorScriptPath }}
      </div>
    </div>
    <div class="detail__row attributes-section">
      <div class="detail__section-title">
        Attributes
      </div>
      <div class="detail__divider" />
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        Last updated:
      </div>
      <div class="title-value-row__value">
        {{ updatedTime }}
      </div>
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        API GUID:
      </div>
      <div class="title-value-row__value">
        {{ environment.guid }}
        <button
          class="action-button"
          @click="copyGUID(environment.guid)"
        >
          <img
            src="/images/copy.svg"
            alt="Copy environment GUID"
            title="Copy GUID to the clipboard"
            class="copy-guid copy-icon"
          >
        </button>
      </div>
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        Image name:
      </div>
      <div class="title-value-row__value">
        {{ environment.name }}
        <button
          class="action-button"
          @click="copyEnvironmentName(environment.name)"
        >
          <img
            src="/images/copy.svg"
            alt="Copy image name"
            title="Copy image name to Clipboard."
            class="copy-guid copy-icon"
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SHOW_INFO_MESSAGE } from '@/store/modules/messages';
import { copyToClipboard } from '@/utils/clipboard';
import { utcToLocalTimezone } from '@/utils/timezone';
import { mapActions } from 'vuex';

export default {
  name: 'EnvironmentsTableRowDetails',
  props: {
    environment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdTime() {
      return utcToLocalTimezone(this.environment.createdTime, 'YYYY/MM/DD h:mm:ss A');
    },
    updatedTime() {
      return utcToLocalTimezone(this.environment.updatedTime, 'YYYY/MM/DD h:mm:ss A');
    },
    anyInstallations() {
      return (
        this.anyQuartoInstallations ||
        this.anyPythonInstallations ||
        this.anyRInstallations ||
        this.anyTensorFlowInstallations
      );
    },
    anyQuartoInstallations() {
      return this.getQuartoInstallations.length > 0;
    },
    getQuartoInstallations() {
      const filtered = this.environment.installations.filter(install => install.type === 'Quarto');
      return filtered.sort(this.sortVersions).reverse();
    },
    anyPythonInstallations() {
      return this.getPythonInstallations.length > 0;
    },
    getPythonInstallations() {
      const filtered = this.environment.installations.filter(install => install.type === 'Python');
      return filtered.sort(this.sortVersions).reverse();
    },
    anyRInstallations() {
      return this.getRInstallations.length > 0;
    },
    getRInstallations() {
      const filtered = this.environment.installations.filter(install => install.type === 'R');
      return filtered.sort(this.sortVersions).reverse();
    },
    anyTensorFlowInstallations() {
      return this.getTensorFlowInstallations.length > 0;
    },
    getTensorFlowInstallations() {
      const filtered = this.environment.installations.filter(install => install.type === 'TensorFlow');
      return filtered.sort(this.sortVersions).reverse();
    },
    supervisorScriptPath() {
      if (this.environment.supervisor) {
        return this.environment.supervisor;
      }
      return 'None';
    }
  },
  methods: {
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    async copyGUID(guid) {
      await copyToClipboard({ value: guid });
      this.setInfoMessage({
        message: 'Execution Environment GUID has been copied to the clipboard',
        autoHide: false,
      });
    },
    async copyEnvironmentName(guid) {
      await copyToClipboard({ value: guid });
      this.setInfoMessage({
        message: 'Execution Environment Image Name has been copied to the clipboard',
        autoHide: false,
      });
    },
    sortVersions(a, b) {
      if (a.version.major > b.version.major) {
        return 1;
      }
      if (a.version.major < b.version.major) {
        return -1;
      }
      if (a.version.minor > b.version.minor) {
        return 1;
      }
      if (a.version.minor < b.version.minor) {
        return -1;
      }
      if (a.version.patch > b.version.patch) {
        return 1;
      }
      if (a.version.patch < b.version.patch) {
        return -1;
      }
      return 0;
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.mt-1rem {
  margin-top: 1rem;
}
.copy-icon {
  height: 14px;
  margin: 3px 0 0 1px;
}
.python-logo {
  width: 5rem;
}
.r-logo {
  width: 1.5rem;
}
.quarto-logo {
  width: 5rem;
  margin-top: 0.5rem;
}
.tensorflow-logo {
  width: 5rem;
}
.container {
  display: flex;
  flex-direction: column;
  background-color: $color-light-grey;
  border-top: 0;
  padding-bottom: 0.5rem;
}
.detail {
  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__section-title {
    line-height: 1rem;
    height: 1rem;
    padding: 0 0.5rem 0 0.5rem;
    font-style: italic;
  }
  &__divider {
    flex: 1 1 60%;
    height: 0.4rem;
    border-bottom: 1px solid $color-light-grey;
    margin: 0.25rem 0.5rem 0 0;
  }
}
.description {
  margin: 0.5rem 0.5rem 0 0.5rem;
  white-space: pre-wrap;

  &__multiline_text {
    padding-left: 0.5rem;
    flex: 1 1;
  }

  &__empty {
    padding-left: 0.5rem;
    flex: 1 1;
    font-style: italic;
  }
}
.installations-section {
  margin-top: 1rem;
}
.installation-list-container {
  margin: 0 0 0 0;
  padding: 0.5rem 0 0 1rem;
  flex: 1 1;
  flex-direction: column;
}
.installation-list {
  margin-left: 2rem;
}
.installation-entry {
  list-style-type: square;
  margin-bottom: 0em;

  &__container {
    display: flex;
    flex-direction: row;
  }
  &__version {
    flex: 0 0 6rem;
  }
  &__path {
    flex: 1 1;
  }
}
.title-value-row {
  margin: 0.5rem 0 0 0.5rem;
  flex-wrap: nowrap;

  &__title {
    margin-left: 0.5rem;
    font-weight: normal;
    flex: 0 0 8rem;
  }
  &__value {
    margin: 0 0.5rem;
    /* min-width required to keep flex from overflowing */
    min-width: 0;
  }
}
.attributes-section {
  padding-top: 1rem;
}
.install-warning {
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
  // From rsc-alert.warning
  color: #93691a;
}
.action-button {
  padding: 0;
  background-color: $color-white;
  @include control-visible-focus;
  height: 22px;
  width: 22px;
}
</style>
