// Copyright (C) 2023 by Posit Software, PBC.

import { Job, JobLogLine } from '@/api/dto/job';
import { taskToPromise } from '@/api/tasks';
import {
  getAppBundles,
  deleteBundle,
  deployBundle,
  getBundleJobs,
} from '@/api/bundle';
import { PARAMETERIZATION_FETCH_VARIANTS } from '@/store/modules/parameterization';
import {
  SET_CONTENT_FRAME_RELOADING,
  LOAD_RENDERINGS_HISTORY,
} from '@/store/modules/contentView';

// Mutations
export const SET_BUNDLES = 'SET_BUNDLES';
export const SET_BUNDLE_JOBS = 'SET_BUNDLE_JOBS';
export const SET_BUNDLES_DISPLAYED_ID = 'SET_BUNDLES_DISPLAYED_ID';
export const SET_BUNDLE_DELETING_FLAG = 'SET_BUNDLE_DELETING_FLAG';
export const SET_BUNDLE_DEPLOYING_FLAG = 'SET_BUNDLE_DEPLOYING_FLAG';
export const SET_BUNDLES_FETCHING_FLAG = 'SET_BUNDLES_FETCHING_FLAG';
export const SET_BUNDLE_JOBS_FETCHING_FLAG = 'SET_BUNDLE_JOBS_FETCHING_FLAG';
export const SET_BUNDLE_LOG_POSITION = 'SET_BUNDLE_LOG_POSITION';
export const SET_BUNDLES_REQ_ERROR = 'SET_BUNDLES_REQ_ERROR';
export const RESET_BUNDLES = 'RESET_BUNDLES';
export const SELECT_BUNDLE = 'SELECT_BUNDLE';
export const PUSH_DEPLOY_LOGS = 'PUSH_DEPLOY_LOGS';
export const TOGGLE_BUNDLES_HISTORY = 'TOGGLE_BUNDLES_HISTORY';

// Actions
export const LOAD_BUNDLES = 'LOAD_BUNDLES';
export const LOAD_BUNDLE_JOBS = 'LOAD_BUNDLE_JOBS';
export const DEPLOY_BUNDLE = 'DEPLOY_BUNDLE';
export const DELETE_BUNDLE = 'DELETE_BUNDLE';
export const TRACK_BUNDLE_DEPLOYMENT = 'TRACK_BUNDLE_DEPLOYMENT';

export const NEW_DEPLOY_KEY = 'new_deploy';

export const defaultState = () => ({
  showHistoryPane: false,
  items: [],
  displayedId: 0,
  isDeploying: false,
  isDeleting: false,
  isFetchingBundles: false,
  isFetchingBundleJobs: false,
  selected: {
    bundleId: 0,
    currentJobKey: null,
    deployLogs: [],
    jobs: [],
  },
  error: null,
});

// "history is already declared in the upper scope"
// we don't care particularly about this one

export default {
  state: defaultState(),
  mutations: {
    [SET_BUNDLES](state, { bundles = [], displayedId = 0 }) {
      state.items = bundles;
      state.displayedId = Number(displayedId);
      state.selected.bundleId = Number(displayedId);
    },
    [SET_BUNDLE_JOBS](state, { bundleId, currentJobKey, jobs }) {
      state.selected.bundleId = Number(bundleId);
      state.selected.currentJobKey = currentJobKey;
      state.selected.jobs = jobs;
      state.selected.deployLogs = [];
    },
    [SET_BUNDLE_DELETING_FLAG](state, flag) {
      state.isDeleting = flag;
    },
    [SET_BUNDLE_DEPLOYING_FLAG](state, flag) {
      state.isDeploying = flag;
    },
    [TOGGLE_BUNDLES_HISTORY](state) {
      state.showHistoryPane = !state.showHistoryPane;
    },
    [SET_BUNDLES_DISPLAYED_ID](state, displayedId) {
      state.displayedId = Number(displayedId);
    },
    [SET_BUNDLES_FETCHING_FLAG](state, flag) {
      state.isFetchingBundles = flag;
    },
    [SET_BUNDLE_JOBS_FETCHING_FLAG](state, flag) {
      state.isFetchingBundleJobs = flag;
    },
    [SET_BUNDLE_LOG_POSITION](state, key) {
      state.selected.currentJobKey = key;
    },
    [SET_BUNDLES_REQ_ERROR](state, error) {
      state.error = error;
    },
    [PUSH_DEPLOY_LOGS](state, logs = []) {
      state.selected.deployLogs.push(...logs);
    },
    [SELECT_BUNDLE](state, bundleId) {
      state.selected.bundleId = Number(bundleId);
    },
    [RESET_BUNDLES](state) {
      state.items = [];
      state.displayedId = 0;
      state.showHistoryPane = false;
      state.selected.bundleId = 0;
      state.selected.currentJobKey = null;
      state.selected.jobs = [];
      state.selected.deployLogs = [];
    },
  },
  actions: {
    [LOAD_BUNDLES]({ commit }, appGuid) {
      commit(SET_BUNDLES_REQ_ERROR, null);
      commit(SET_BUNDLES_FETCHING_FLAG, true);
      return getAppBundles(appGuid)
        .then(bundles => {
          const active = bundles.find(b => b.active);
          commit(SET_BUNDLES, { bundles, displayedId: active?.id });
        })
        .catch(err => {
          commit(SET_BUNDLES_REQ_ERROR, err);
        })
        .finally(() => {
          commit(SET_BUNDLES_FETCHING_FLAG, false);
        });
    },
    [LOAD_BUNDLE_JOBS]({ commit }, { appId, bundleId, jobTag }) {
      commit(SET_BUNDLES_REQ_ERROR, null);
      commit(SET_BUNDLE_JOBS_FETCHING_FLAG, true);
      return getBundleJobs(appId, bundleId, jobTag)
        .then(jobs => {
          const jobsPayload = { bundleId, jobs };
          if (jobs.length) {
            jobsPayload.currentJobKey = jobs[jobs.length - 1].key;
          }
          commit(SET_BUNDLE_JOBS, jobsPayload);
        })
        .catch(err => {
          commit(SET_BUNDLES_REQ_ERROR, err);
        })
        .finally(() => {
          commit(SET_BUNDLE_JOBS_FETCHING_FLAG, false);
        });
    },
    [DEPLOY_BUNDLE]({ commit, dispatch }, { appId, bundleId }) {
      commit(SET_BUNDLES_REQ_ERROR, null);
      commit(SET_BUNDLE_DEPLOYING_FLAG, true);
      return deployBundle(appId, bundleId)
        .then(task => {
          return dispatch(TRACK_BUNDLE_DEPLOYMENT, { bundleId, taskId: task.id });
        })
        .catch(err => {
          commit(SET_BUNDLES_REQ_ERROR, err);
        })
        .finally(() => {
          commit(SET_BUNDLE_DEPLOYING_FLAG, false);
        });
    },
    [DELETE_BUNDLE]({ commit, dispatch }, { appGuid, bundleId }) {
      commit(SET_BUNDLES_REQ_ERROR, null);
      commit(SET_BUNDLE_DELETING_FLAG, true);
      return deleteBundle(appGuid, bundleId)
        .then(() => {
          return dispatch(LOAD_BUNDLES, appGuid);
        })
        .catch(err => {
          commit(SET_BUNDLES_REQ_ERROR, err);
        })
        .finally(() => {
          commit(SET_BUNDLE_DELETING_FLAG, false);
        });
    },
    [TRACK_BUNDLE_DEPLOYMENT]({ rootState, commit, dispatch }, { bundleId, taskId }) {
      const newJob = new Job({
        key: NEW_DEPLOY_KEY,
        stdout: 'Starting Deployment...',
      });
      commit(SET_BUNDLE_JOBS, {
        bundleId,
        jobs: [newJob],
        currentJobKey: NEW_DEPLOY_KEY,
      });
      const { app } = rootState.contentView;
      const onPoll = ({ output }) => {
        commit(
          PUSH_DEPLOY_LOGS,
          output.map(line => new JobLogLine({ line, isError: false })),
        );
      };
      return taskToPromise(taskId, onPoll)
        .then(() => {
          commit(SET_CONTENT_FRAME_RELOADING, true);
          return dispatch(LOAD_BUNDLES, app.guid);
        })
        .then(() => {
          return dispatch(PARAMETERIZATION_FETCH_VARIANTS, app.id);
        })
        .then(() => {
          // If current variant loaded, pull renderings history
          const variantId = rootState.parameterization.currentVariant?.id;
          if (variantId) {
            return dispatch(LOAD_RENDERINGS_HISTORY, variantId);
          }
        });
    },
  },
};
