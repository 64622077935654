<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import RSButton from '@/elements/RSButton.vue';
import { HIDE_LOCKED_MESSAGE_PREVIEW } from '@/store/modules/contentView';
import LockedMessage from '@/views/locked-content/LockedMessage.vue';
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import CollapsiblePanel from '../../../components/CollapsiblePanel.vue';

const store = useStore();

const lockedContentEnabled = computed(
  () => store.state.server.settings.lockedContentEnabled
);
const showLockedMessagePreview = computed(
  () => store.state.contentView.showLockedMessagePreview
);
const lockedMessage = computed(
  () => store.state.contentView.lockedMessagePreview
);

watch(showLockedMessagePreview, () => {
  const hidePreview = () => {
    store.commit(HIDE_LOCKED_MESSAGE_PREVIEW);
  };

  if (showLockedMessagePreview.value) {
    window.addEventListener('click', hidePreview);
    return;
  }
  window.removeEventListener('click', hidePreview);
});
</script>

<template>
  <CollapsiblePanel
    v-if="lockedContentEnabled"
    :hide-header="true"
    :is-expanded="showLockedMessagePreview"
  >
    <template #content>
      <div
        v-if="lockedContentEnabled"
        class="preview-container"
        data-automation="locked-message-preview"
        @click.stop
      >
        <RSButton
          class="close-button"
          data-automation="close-locked-message-preview"
          label="Close"
          size="small"
          type="primary"
          :use-label-width="true"
          @click.stop="store.commit(HIDE_LOCKED_MESSAGE_PREVIEW)"
        />

        <div class="message-container">
          <LockedMessage
            :is-active="false"
            :message="lockedMessage"
          />
        </div>
      </div>
    </template>
  </CollapsiblePanel>
</template>

<style lang="scss">
@import 'Styles/shared/_colors';

.preview-container {
  background-color: $color-light-grey-2;
  background-color: lighten($color-alert-warning, 20%);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15);
  left: 0;
  margin-bottom: 5px;
  opacity: 0.95;
  padding: 2.5rem 2rem 2rem;
  position: relative;
  top: 0;
  z-index: 10;

  .close-button {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    padding: 0.4rem 0.8rem;
  }

  .message-container {
    display: flex;
    justify-content: center;
    max-height: 75vh;
    overflow-y: auto;
  }
}
</style>
