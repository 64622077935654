<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    aria-label="Refresh Report"
    :disabled="isBusy || !isInitialized"
    title="Refresh Report"
    class="action update content-actions__refresh-button"
    data-automation="menuitem-refresh-report"
    @click="refreshReport"
  />
</template>

<script>
import { PARAMETERIZATION_REFRESH_REPORT } from '@/store/modules/parameterization';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'RefreshButton',
  computed: {
    ...mapState({
      currentVariant: state => state.parameterization.currentVariant,
      isBusy: state => state.parameterization.isBusy,
    }),
    isInitialized() {
      return !isEmpty(this.currentVariant);
    },
  },
  methods: {
    ...mapActions({
      refreshReport: PARAMETERIZATION_REFRESH_REPORT,
    }),
  },
};
</script>
