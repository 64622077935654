<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template> 
  <div v-if="serverSettings.oauthIntegrationsEnabled && associations.available.length > 0 && ready">
    <Dropdown
      v-model="associations.activeKeys"
      name="oauth-association-options"
      label="Available Integrations"
      :options="associations.inactive"
      :pre-selected="associations.active"
      :filterable="false"
      :disabled="disabled"
      @update:model-value="onAssociationDropdownChange"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import { mapState } from 'vuex';
import { differenceBy } from 'lodash';

export default {
  name: 'AuthIntegrations',
  components: {
    Dropdown,
  },
  props: {
    active: {
      type: Array,
      default: () => [],
    },
    activeKeys: {
      type: Array,
      default: () => [],
    },
    available: {
      type: Array,
      default: () => [],
    }
  },
  emits: ['integrationChange'],
  data() {
    return {
      ready: false,
      disabled: false,
      associations: {
        initial: [],
        active: [],
        activeKeys: [],
        inactive: [],
        available: [],
      },
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    })
  },
  watch: {
    activeKeys: {
      handler() {
        this.associations.activeKeys = this.activeKeys;
        this.associations.active = this.active;
        this.associations.inactive = 
          differenceBy(this.associations.available, this.associations.active, 'value').sort(
            (first, second) => {
              if (first.label < second.label) {
                return -1;
              } else if (first.label > second.label) {
                return 1;
              } return 0;
            }
          );
      },
    }
  },
  mounted() {
    this.oauthInfo();
    this.disabled = !this.currentUser.canEditAppSettings(this.app);
    this.ready = true;
  },
  methods: {
    oauthInfo() {
      if (
        this.serverSettings.oauthIntegrationsEnabled &&
        this.currentUser.canEditAppSettings(this.app))
      {
        this.associations.active = this.active;
        this.associations.activeKeys = this.activeKeys;
        const associations = this.available;
        for (const association of associations) {
          this.associations.available.push(
            {
              label: association.name,
              value: association.guid,
              sub: association.description,
              template: association.template
            }
          );
        };
        this.associations.initial = this.associations.active;
        this.associations.inactive = this.associations.available.filter(
          el => !this.associations.activeKeys.includes(el.value)
        );
        this.associations.inactive.sort((first, second) => {
          if (first.label < second.label) {
            return -1;
          } else if (first.label > second.label) {
            return 1;
          } return 0;
        });
      }
    },
    onAssociationDropdownChange(value) {
      // because we (currently) only allow one integration per piece of content,
      // remove old selection when choosing a new one.
      this.associations.activeKeys = [value.pop()];
      this.associations.active = this.associations.available.filter(
        el => this.associations.activeKeys.includes(el.value)
      );
      // If/when we allow multiple integrations, remove above lines and uncomment below.
      //  this.associations.active = this.associations.available.filter(el => value.includes(el.value));
      this.associations.inactive = differenceBy(this.associations.available, this.associations.active, 'value');
      this.$emit('integrationChange', this.associations.active);
    },
  }
};
</script>
<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.groupHeadings {
  color: $color-heading;
  letter-spacing: .1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.spaceAfter {
  margin-bottom: 0.5rem;
}

:deep(.dropdown__button) {
  background-color: $color-white;
  outline: 1px solid $color-medium-grey;
  width: 100%;
  justify-content: space-between;
  height: 36px;
  font-size: 0.85rem;
}

:deep(.dropdown__selections-label),
:deep(.dropdown__selections-count) {
  display: none;
}

:deep(.checkbox-fieldset__option) {
  flex-wrap: wrap;

  &:first-of-type {
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  &:last-of-type {
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
}

:deep(.checkbox-fieldset__option) {
  .checkbox-fieldset__option-sub {
    text-align: left;
    text-wrap: auto;
    line-height: 15px;
    margin: auto auto 0.5rem 0;
    width: 100%;
    padding-left: 30px;
    max-height: 45px;
    position: relative;
    overflow: hidden;
    &::after {
      content: '';
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30%;
      height: 15px;
      background: linear-gradient(to right, #fff0, #fff 50%);
    }
  }
  &:hover {
    .checkbox-fieldset__option-sub::after {
      background: linear-gradient(to right, #fff0, #EDF1F6 50%);
    }
  }
}
</style>
