<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import ImageFileInput from '@/components/ImageFileInput.vue';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';

defineProps({
  thumbnail: { type: String, required: false, default: null },
  readOnly: { type: Boolean, required: false, default: false },
});

const emit = defineEmits(['input']);
</script>

<template>
  <div>
    <RSInformationToggle>
      <template #title>
        <div class="groupHeadings">
          Thumbnail
        </div>
      </template>

      <template #help>
        <p>
          You can upload a JPEG, PNG, GIF, or SVG image that represents the
          content. Unlike title or description, users who cannot view the
          content (including administrators) are also unable to view the image
          you have uploaded. This way, if you wish to upload a screenshot of the
          content, any information contained in that screenshot will not leak to
          users who would not otherwise be able to access it.
        </p>
      </template>
    </RSInformationToggle>

    <div class="formSection">
      <ImageFileInput
        data-automation="settings-info-image-display"
        :thumbnail="thumbnail"
        :read-only="readOnly"
        @input="value => emit('input', value)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.formSection {
  margin-top: 1rem;
}

.groupHeadings {
  color: $color-heading;
  letter-spacing: 0.1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
</style>
