<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
  <!--
    To maintain backwards functionality,
    catch the escape key from any open tab to return to the default access tab.
  -->
  <div
    id="settingsPanel"
    class="contentPanel"
    data-automation="app-settings-main-pane"
    @keydown.esc.prevent.stop="resetToAccessTab"
  >
    <!-- eslint-enable vuejs-accessibility/no-static-element-interactions -->

    <div
      v-if="renderPanelComponents"
      class="appsettings settingsPane"
    >
      <div class="formSection">
        <ContentSettingsTabs
          v-if="!appHasError"
          :tabs="tabs"
          :active-tab-id="activeTabId"
          @update:active-tab-id="navigateTo"
        />
      </div>

      <!-- Tab content -->
      <RouterView
        v-if="isAuthorized"
        name="settings"
      />
    </div>
    <div
      v-if="appHasError"
      class="notfound"
      data-automation="app-settings-not-found"
    >
      <div
        v-if="requiresAuth"
        data-automation="app-settings-not-found__no-auth"
      >
        <h2>You're Not Logged In</h2>
        <p class="rsc-lead">
          You must log in to view and edit content settings.
        </p>
        <a
          class="button default"
          :href="loginUrl"
        >
          Log In
        </a>
      </div>
      <div
        v-else
        data-automation="app-settings-not-found__generic"
      >
        <h2>Not found</h2>
        <p class="rsc-lead">
          This application is missing or not visible to you.
        </p>
        <p>
          The application may have been deleted, or you may have followed an incorrect link.
        </p>
        <RouterLink
          class="button default"
          :to="{ name: 'contentList' }"
        >
          Back to content list
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { loginPath } from '@/utils/paths';
import { debounce, isEmpty } from 'lodash';
import { RouterLink, RouterView } from 'vue-router';
import { mapState } from 'vuex';
import ContentSettingsTabs from './ContentSettingsTabs.vue';

const TabRoute = {
  Info: 'apps.info',
  Access: 'apps.access',
  Runtime: 'apps.runtime',
  Output: 'apps.output',
  Tags: 'apps.tags',
  Environment: 'apps.environment',
};

export default {
  name: 'ContentSettingsPane',
  components: {
    ContentSettingsTabs,
    RouterLink,
    RouterView,
  },
  data: () => ({
    activeTabId: 'access',
    renderPanelComponents: true
  }),
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      appError: state => state.contentView.appError,
      requiresAuth: state => state.contentView.requiresAuth,
      isAuthorized: state => !state.contentView.isUnauthorized,
      currentVariant: state => state.parameterization.currentVariant,
      showSettingsPanel: state => state.contentView.showSettingsPanel,
    }),
    appHasError() {
      return this.requiresAuth || !!this.appError;
    },
    loginUrl() {
      return loginPath({ redirect: location.href });
    },
    useVariantRouting() {
      return !isEmpty(this.currentVariant);
    },
    tabs() {
      return [
        {
          id: 'info',
          name: 'Info',
          route: this.tabRoute(TabRoute.Info),
          testId: 'app-settings-tab__info',
        },
        {
          id: 'access',
          name: 'Access',
          route: this.tabRoute(TabRoute.Access),
          testId: 'app-settings-tab__access',
        },
        {
          id: 'runtime',
          name: 'Runtime',
          route: TabRoute.Runtime,
          testId: 'app-settings-tab__runtime',
        },
        {
          id: 'schedule',
          name: 'Schedule',
          route: this.tabRoute(TabRoute.Output),
          testId: 'app-settings-tab__schedule',
        },
        {
          id: 'tags',
          name: 'Tags',
          route: this.tabRoute(TabRoute.Tags),
          testId: 'app-settings-tab__tags',
        },
        {
          id: 'environmentVariables',
          name: 'Vars',
          route: TabRoute.Environment,
          testId: 'app-settings-tab__environmentVariables',
        },
      ];
    },
  },
  watch: {
    // debounce is used to here to prevent jankiness that results
    // when the settings panel is closed and the settings components
    // disappear before the animation is complete. `debounce` instead of
    // `delay` or `setTimeout` to prevent the possibility of rapid clicking
    // causing the settings panel to appear with no components.
    showSettingsPanel() {
      debounce(function() {
        if (!this.showSettingsPanel) {
          this.renderPanelComponents = false;
          const routeParams = { idOrGuid: this.app.guid };
          this.$router.push({
            name: 'apps',
            params: routeParams,
            replace: true,
          });
          return;
        }
        this.renderPanelComponents = true;
        // default to first tab on render
        this.resetToAccessTab();
      }, 100).apply(this);
    },
  },
  created() {
    this.renderPanelComponents = this.showSettingsPanel;
    this.activeTabId = this.tabs.find(tab => this.$route.name === tab.route)?.id || 'access';
  },
  methods: {
    findTabById(tabName) {
      return this.tabs.find(tab => tab.id === tabName);
    },
    tabRoute(routeName) {
      return routeName + (this.useVariantRouting ? '.variant' : '');
    },
    navigateTo(tabId) {
      this.activeTabId = tabId;
      const tab = this.findTabById(this.activeTabId);

      const routeParams = { idOrGuid: this.app.guid };
      if (tab.route.match(/\.variant$/)) {
        routeParams.id = this.currentVariant.id;
      }

      this.$router.push({
        name: tab.route,
        params: routeParams,
        replace: true,
      });
    },
    resetToAccessTab() {
      this.navigateTo('access');
    },
  },
};
</script>

<style lang="scss" scoped>
.notfound {
  position: relative;
  background-color: #f8f8f8;
}

.disabled.disabled {
  background-color: pink;
}
</style>
