<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <Transition name="slide">
    <div
      v-if="shouldBeVisible"
      class="log-overlay"
    >
      <FocusLock
        v-if="shouldBeVisible"
        :no-focus-guards="true"
      >
        <div
          tabindex="0"
          class="log-overlay__container"
          data-automation="log-overlay"
        >
          <div
            class="log-overlay__actions"
          >
            <div class="log-overlay__actions-section section-1">
              <select
                v-if="hasLogs"
                ref="jobFilter"
                v-model="logFilter"
                class="log-overlay__actions-job-filter"
                aria-label="Filter logs by type"
              >
                <option
                  v-for="(tag, i) in jobTypes"
                  :key="i"
                  :value="tag"
                >
                  {{ tag.description }}
                </option>
              </select>
              <SearchSelect
                v-if="hasLogs"
                label="Job Log Selection"
                title="Select a Job Log"
                :selected="selectedOption"
                :options="options"
                class="log-overlay__select"
                @select="selectHandler"
              />
            </div>
            <div class="log-overlay__actions-section">
              <span class="log-overlay__wrap">
                <RSInputCheckbox
                  v-if="!noLogs"
                  v-model="wrapLongLines"
                  label="Wrap long lines"
                  data-automation="log-overlay__wrap"
                  name="wrapLongLines"
                />
              </span>
              <a
                v-if="!noLogs"
                class="log-overlay__download"
                :href="downloadUrl"
              >
                <img
                  src="/images/download-icon.png"
                  alt=""
                >
                <span class="log-overlay__download-text">
                  Download Log
                </span>
              </a>
              <div class="log-overlay__search">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="log-overlay__search-icon"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  alt=""
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <input
                  ref="logsearch"
                  v-model="searchText"
                  placeholder="Find in log"
                  class="log-overlay__search-input"
                  data-automation="log-overlay__search-input"
                  aria-label="Search current log"
                  @keydown.enter.shift.exact="decrementResult"
                  @keydown.enter.exact="incrementResult"
                >
                <Transition name="grow">
                  <span
                    v-if="searchText !== ''"
                    class="log-overlay__search-results"
                  >
                    <div
                      class="log-overlay__search-result-count"
                      :class="searchCount === 0 && 'disabled'"
                    >
                      {{ searchCurrent }}/{{ searchCount }}
                    </div>
                    <button
                      class="log-overlay__search-result previous"
                      aria-label="Previous Result"
                      :disabled="searchCount === 0"
                      @click="decrementResult"
                    />
                    <button
                      class="log-overlay__search-result next"
                      aria-label="Next Result"
                      :disabled="searchCount === 0"
                      @click="incrementResult"
                    />
                  </span>
                </Transition>
              </div>
            </div>
          </div>
          <div class="log-overlay__header">
            <div
              v-if="!app.isExecutable()"
              class="log-overlay__title"
              data-automation="log-overlay__not-executable"
            >
              The source code for this content was not published.
              It is not executable and has no logs.
            </div>
            <div
              v-else-if="noLogs"
              class="log-overlay__title"
            >
              No logs found for this {{ ContentTypeLabel[appContentType] }}.
              <div v-if="app.hasWorker()">
                Visit this {{ ContentTypeLabel[appContentType] }} to run a process for it.
              </div>
              <div v-if="app.isRenderable()">
                Regenerate this {{ ContentTypeLabel[appContentType] }} to run a process for it.
              </div>
            </div>
            <div
              v-else-if="currentJob"
              class="log-overlay__title"
              :class="{ running: currentJob.endTime == null && !currentJob.finalized }"
            >
              {{ currentJob.title }}
              <div
                class="log-overlay__subtitle"
                :title="`Job Key: ${currentJob.key}\nHostname: ${currentJob.hostname}`"
              >
                {{ currentJob.subtitle }}
              </div>
            </div>
            <div
              v-if="currentJob && currentJob.isError()"
              class="log-overlay__summary logSummary error"
            >
              <p>
                This process ended with an error.
                <span v-if="currentJob.errorCode()">
                  For more info about this error:
                  <a
                    :href="helpURL"
                    target="_blank"
                  >
                    {{ currentJob.errorCode() }} in the User Guide
                  </a>
                </span>
              </p>
            </div>
            <div
              v-if="currentJob && currentJob.isGone()"
              class="log-overlay__summary logSummary warning"
            >
              This process did not generate an error but ended unexpectedly.
            </div>
          </div>
          <LogOutput
            v-if="app && currentJob"
            :app="app"
            :job="currentJob"
            :search-text="searchText"
            :search-current="searchCurrent"
            :wrap-long-lines="wrapLongLines"
            @matches="searchMatchHandler"
            @reset="resetSearchHandler"
          />
        </div>
      </FocusLock>
    </div>
  </Transition>
</template>

<script>
import { ContentTypeLabel } from '@/api/dto/app';
import { downloadJobLogPath } from '@/api/jobs';
import SearchSelect from '@/components/SearchSelect';
import RSInputCheckbox from '@/elements/RSInputCheckbox';
import {
  LOGS_OVERLAY_CLEAR,
  LOGS_OVERLAY_FETCH_APP,
  LOGS_OVERLAY_FETCH_APP_JOB,
  LOGS_OVERLAY_FETCH_APP_JOB_LIST,
  LOGS_OVERLAY_FETCH_BUNDLES,
  LOGS_OVERLAY_FETCH_TYPES,
  LOGS_OVERLAY_RESET_VIEW,
} from '@/store/modules/logsOverlay';
import { docsPath, jobPath } from '@/utils/paths';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import FocusLock from 'vue-focus-lock';
import { mapActions, mapMutations, mapState } from 'vuex';
import LogOutput from './LogOutput';

dayjs.extend(relativeTime);

export default {
  name: 'LogsOverlay',
  components: {
    FocusLock,
    LogOutput,
    RSInputCheckbox,
    SearchSelect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    appId: {
      type: Number,
      required: true,
    },
    jobKey: {
      type: String,
      default: '',
    }
  },
  emits: ['hide', 'show'],
  data() {
    return {
      ContentTypeLabel,
      currentKey: null,
      isMounting: true,
      logFilter: { value: 'All', description: 'All logs' },
      poller: null,
      searchCount: 0,
      searchCurrent: 0,
      searchText: '',
      selectedOption: {},
      viewShouldReset: false,
      wrapLongLines: false,
    };
  },
  computed: {
    // TODO: there is some state and actions that could be removed
    // from logsOverlay and use the contentView.app that resolves at router level.
    ...mapState({
      app: state => state.logsOverlay.app,
      jobs: state => state.logsOverlay.jobs,
      currentJob: state => state.logsOverlay.currentJob,
      createdBundles: state => state.logsOverlay.createdBundles,
      jobTypes: state => state.logsOverlay.jobTypes,
    }),
    options() {
      const filtered = this.filterJobs(this.logFilter);

      return filtered.map(job => {
        let extraClass, groupName;
        const text = `${job.title}\n${job.subtitle}`;
        if (job.isError()) {
          extraClass = 'error';
        } else if (job.isGone()) {
          extraClass = 'warning';
        } else if (job.isRunning()) {
          extraClass = 'running';
        }
        if (job.bundleId === this.app.bundleId) {
          groupName = `Active: Bundle ${job.bundleId} (Published ${dayjs(this.createdBundles[job.bundleId]).fromNow()})`;
        } else {
          groupName = `Bundle ${job.bundleId} (Published ${dayjs(this.createdBundles[job.bundleId]).fromNow()})`;
        }
        return { value: job.key, text: text, group: groupName, class: extraClass };
      });
    },
    hasLogs() {
      return (this.jobs.length > 1);
    },
    noLogs() {
      return (this.jobs.length === 0);
    },
    appContentType() {
      return this.app.contentType();
    },
    helpURL() {
      return docsPath(`user/troubleshooting/#${this.currentJob.errorCode()}`);
    },
    shouldBeVisible() {
      return this.visible && this.app;
    },
    downloadUrl() {
      return this.app.guid && this.currentKey ? downloadJobLogPath(this.app.guid, this.currentKey) : '';
    },
  },
  watch: {
    currentKey(key) {
      this.fetchAppJob(key).then(() => {
        this.selectedOption = this.options.find(option => option.value === this.currentJob.key);
      });
    },
    searchText() {
      this.searchCurrent = 0;
    },
    hasLogs: {
      immediate: true,
      handler() {
        if (this.hasLogs && this.shouldBeVisible) {
          this.$nextTick().then(() => {
            this.$refs.jobFilter.focus();
          });
        }
      }
    },
    shouldBeVisible() {
      if (this.shouldBeVisible) {
        if (this.viewShouldReset) {
          this.resetView();
          this.viewShouldReset = false;
        }
        this.poller = setInterval(() => this.fetchAppJobList(), 3000);
        this.$nextTick().then(() => {
          this.$refs.jobFilter?.focus();
        });
      } else {
        clearInterval(this.poller);
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeypress);
    window.addEventListener('message', this.handleMessage);
    // eventually we'll want this to be a direct VueX mutation
    document.addEventListener('logs.reset', this.triggerViewReset);
    this.fetchApp(this.appId).then(() => this.fetchAppJobList().then(() => {
      if (this.jobs.length > 0) {
        const jobKeyExistsInJobs = () => this.jobs.some(job => job.key === this.jobKey);

        this.currentKey = this.jobKey && jobKeyExistsInJobs() ? this.jobKey : this.jobs[0].key;
        this.fetchAppJob(this.currentKey);
        this.fetchTypes();
        this.fetchBundles();
      }
      this.isMounting = false;
    }));
  },
  unmounted() {
    this.clearLogs();
    document.removeEventListener('keydown', this.handleKeypress);
    window.removeEventListener('message', this.handleMessage);
    document.removeEventListener('logs.reset', this.triggerViewReset);
    clearInterval(this.poller);
  },
  methods: {
    ...mapActions({
      fetchApp: LOGS_OVERLAY_FETCH_APP,
      fetchAppJobList: LOGS_OVERLAY_FETCH_APP_JOB_LIST,
      fetchAppJob: LOGS_OVERLAY_FETCH_APP_JOB,
      resetView: LOGS_OVERLAY_RESET_VIEW,
      fetchTypes: LOGS_OVERLAY_FETCH_TYPES,
      fetchBundles: LOGS_OVERLAY_FETCH_BUNDLES,
    }),
    ...mapMutations({
      clearLogs: LOGS_OVERLAY_CLEAR,
    }),
    triggerViewReset() {
      this.viewShouldReset = true;
    },
    hide() {
      this.searchText = '';
      this.$emit('hide');
    },
    selectHandler(key) {
      this.currentKey = key;
      history.pushState({}, '', jobPath(this.app.guid, this.currentKey));
      this.$nextTick().then(() => {
        this.$refs.jobFilter.focus();
      });
    },
    searchMatchHandler(numMatches) {
      this.searchCount = numMatches;
    },
    resetSearchHandler() {
      if (this.searchCount > 0 && this.searchCurrent === 0) {
        this.searchCurrent = 1;
      }
    },
    incrementResult() {
      if (this.searchCount === 0) { return; }
      if (this.searchCurrent === this.searchCount) {
        this.searchCurrent = 1;
      } else {
        this.searchCurrent++;
      }
    },
    decrementResult() {
      if (this.searchCount === 0) { return; }
      if (this.searchCurrent === 1) {
        this.searchCurrent = this.searchCount;
      } else {
        this.searchCurrent--;
      }
    },
    handleMessage(e) {
      if (e.data.key) { this.handleKeypress(e.data); }
    },
    handleKeypress(e) {
      if (e.key === 'Escape') {
        if (document.activeElement === this.$refs.logsearch) {
          this.$nextTick().then(() => {
            this.$refs.jobFilter.focus();
          });
        } else {
          this.hide();
        }
      }
      if (['INPUT', 'SELECT', 'TEXTAREA'].includes(e.target?.nodeName)) { return; }
      if (e.key === '/') {
        e.preventDefault();
        this.$nextTick().then(() => {
          this.$refs.logsearch.focus();
        });
      } else if (e.key === '~') {
        if (this.visible === false) {
          this.$emit('show');
        } else {
          this.hide();
        }
      }
    },
    filterJobs(filter) {
      let results;
      if (filter && filter.value !== 'All') {
        results = this.jobs.filter(job => job.tag === filter.value);
      } else { results = this.jobs; }
      if (!this.isMounting && !results.some(job => job.key === this.currentKey)) {
        this.currentKey = results[0].key;
      }
      return results;
    },
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

@mixin active-icon {
  content: '';
  display: inline-block;
  background: rgb(100, 210, 100);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  box-shadow: 0 0 0 0 rgba(100, 210, 100, 0.7);
  animation: pulse 2s ease-in-out infinite;
}

.log-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

}

.log-overlay__container {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-direction: column;
  min-height: 30em;
  height: 60vh;
  background-color: $color-white;
  box-shadow: 0 8px 8px rgb(0, 0, 0, 0.1);
  @include control-visible-focus();
}

.log-overlay__header {
  padding: 1rem;
}

.log-overlay__search {
  height: 30px;
  background-color: $color-white;
  border: 1px solid rgba(138, 147, 158, 0.4);
  display: flex;
  align-items: center;
  padding: 0 6px;
  border-radius: 8px;
  &:focus-within {
    @include control-focus();
  }
  @media (max-width: 500px) {
    width: 100%;
  }
}

.log-overlay__search-icon {
  color: $color-dark-grey;
  width: 20px;
  height: 20px;
}

.log-overlay__search-input {
  color: $color-dark-grey-3;
  flex-grow: 1;
  background-color: inherit;
  border: none;
  outline: none;
  padding: 0 0 0 6px;
  &:focus {
    outline: none;
  }
}

.log-overlay__search-results {
  align-items: center;
  display: flex;
}

.log-overlay__search-result {
  background-image: url('/images/elements/iconDownArrow.svg');
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 20px 20px;
  background-position: center;
  padding: 10px;
  margin: 0;
  &.previous {
    transform: rotate(180deg);
  }
  &:disabled {
    background-color: transparent;
  }
}

.log-overlay__search-result-count {
  color: $color-dark-grey;
  font-size: 12px;
  padding: 4px;
  text-align: center;
  &.disabled {
    opacity: 0.6;
  }
}

.log-overlay__title {
  color: black;
  font-size: 1.2rem;
  font-weight: 700;

  @media (max-width: 500px) {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.running {
    padding-left: 20px;
    &::before {
      @include active-icon;
      transform: translate(-20px, 3px);
    }
  }
  .log-overlay__subtitle {
    margin-top: 4px;
    font-weight: 400;
    font-size: 1rem;
  }
}

.log-overlay__summary {
  margin-top: 0.5rem;

  p {
    line-height: 1rem;
  }
}

.log-overlay__actions {
  background-color: #f4f7fb;
  border: 1px solid rgba(138, 147, 158, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 0.5rem 1rem;
  @media (max-width: 500px) {
    flex-direction: column;
  }
}

.log-overlay__actions-section {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: center;

  @media (max-width: 500px) {
    width: 100%;
    padding: 0.15rem 0;
    flex-direction: row-reverse;
    &.section-1 {
      flex-direction: column;
    }
  }

  .log-overlay__wrap.log-overlay__wrap {
    padding: 0.25rem .5rem;
    margin-bottom: 0;
  }

  .log-overlay__download {
    padding: 0.25rem .5rem;
    border: 1px solid transparent;
    height: 30px;
    color: #000;
    align-self: center;
    display: flex;
    align-items: center;
    margin-right: 8px;
    img {
      height: 18px;
      padding-right: 0.5rem;
    }
    @media (max-width: 500px) {
      margin-right: 0;
      img {
        padding-right: 0;
      }
      .log-overlay__download-text {
        display: none;
      }
    }
    &:focus {
      @include control-focus();
    }
    &:focus-visible {
      @include control-focus;
    }
  }
}

.log-overlay__actions-job-filter {
  height: 30px;
  appearance: none;
  background-image: url('/images/elements/iconDownArrow.svg');
  background-repeat: no-repeat;
  background-position: center right 6px;
  background-size: 20px 20px;
  border: 1px solid rgba(138, 147, 158, 0.4);
  border-radius: 8px 0 0 8px;
  margin-right: 1px;
  padding: 6px;
  padding-right: 32px;
  &:hover {
    background-color: $color-light-grey;
  }
  &:focus {
    @include control-focus;
  }
  &:focus-visible {
    @include control-focus;
  }
  &:focus-visible {
    @include control-focus;
  }
  @media (max-width: 500px) {
    border-radius: 8px;
    margin: 0.25em 0;
  }
}

.search-select.log-overlay__select {
  border: 1px solid rgba(138, 147, 158, 0.4);
  border-radius: 0 8px 8px 0;
  margin: 0;
  height: 30px;
  @media (max-width: 500px) {
    border-radius: 8px;
  }
  .search-select__input {
    border: none;
    border-radius: 0 8px 8px 0;
    font-size: 14px;
    height: 28px; //account for border
    width: 250px;
    &:focus {
      @include control-focus;
    }
    &:focus-visible {
      @include control-focus;
    }
    @media (max-width: 500px) {
      border-radius: 8px;
      width: 100%;
    }
  }
  .search-select__close {
    background-color: transparent;
  }
  .search-select__options {
    .search-select__option {
      padding-right: 32px;
      &.error::before {
        content: url('/images/elements/error.svg');
        transform: translateY(-24px) scale(.3);
        left: calc(100% - 48px);
        display: inline-block;
        position: absolute;
      }
      &.warning::before {
        content: url('/images/elements/warning.svg');
        transform: translateY(-24px) scale(.3);
        left: calc(100% - 48px);
        display: inline-block;
        position: absolute;
      }
      &.running::before {
        @include active-icon;
        left: calc(100% - 24px);
      }
      span {
        white-space: pre;
      }
    }
  }
}

.log-overlay .logPreviewErrorHelp {
  padding-bottom: 0.5rem;
  background: none;
  .logMessage {
    padding-left: 1rem;
  }
  hr {
    margin-top: 0;
  }
}

.slide-enter-active, .slide-leave-active {
  transition: top 0.5s ease;
}

.slide-enter-from, .slide-leave-to {
  top: -70%;
}

.slide-enter-to, .slide-leave-from {
  top: 0;
}

.grow-enter-from, .grow-leave-to {
  visibility: hidden;
  width: 0;
}
.grow-enter-to, .grow-leave-from {
  width: 90px;
}

.grow-enter-active, .grow-leave-active {
  transition: width .2s linear;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(100, 210, 100, 0.7);
  }
  50% {
    box-shadow: 0 0 6px 4px rgba(100, 210, 100, 0.4);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(100, 210, 100, 0.7);
  }
}
</style>
